import logo from './logo.png';
const products = [
  {
    id: 1,
    name: 'Assembled Frame Box With Metal Cover',
    href: '#',
    imageSrc: 'images/products/assembled.jpg',
    imageAlt: "Assembled Frame Box",
    price: ''
  },
  {
    id: 2,
    name: 'Assembled Frame Box With Lid',
    href: '#',
    imageSrc: 'images/products/assembled2.jpg',
    imageAlt: "Assembled Frame Box",
    price: ''
  },
  {
    id: 3,
    name: 'Assembled Frame Box With Inner Cover',
    href: '#',
    imageSrc: 'images/products/assembled3.jpg',
    imageAlt: "Assembled Frame Box",
    price: ''
  },
  {
    id: 4,
    name: 'Deep box',
    href: '#',
    imageSrc: 'images/products/box2.jpg',
    imageAlt: "Deep box",
    price: '$12'
  },
  {
    id: 5,
    name: 'Wax Dipped Migratory Lid',
    href: '#',
    imageSrc: 'images/products/lid2.jpg',
    imageAlt: "Wax Dipped Migratory Lid",
    price: '$12'
  }, 
  {
    id: 6,
    name: 'Medium super box',
    href: '#',
    imageSrc: 'images/products/box5.jpg',
    imageAlt: "Medium super box",
    price: '$12'
  },
  {
    id: 7,
    name: 'Telescope Metal Cover',
    href: '#',
    imageSrc: 'images/products/lid3.jpg',
    imageAlt: "Telescope Metal Cover",
    price: '$25'
  },
  {
    id: 8,
    name: 'Wax Dipped Solid Bottom Board',
    href: '#',
    imageSrc: 'images/products/board3.jpg',
    imageAlt: "Wax Dipped Solid Bottom Board",
    price: '$12'
  },   
  {
    id: 9,
    name: 'Inner Cover',
    href: '#',
    imageSrc: 'images/products/board4.jpg',
    imageAlt: "Inner Cover",
    price: '$9'
  },
  {
    id: 10,
    name: 'Entrance Reducer',
    href: '#',
    imageSrc: 'images/products/acc1.jpg',
    imageAlt: "Entrance Reducer",
    price: '$2'
  },
  {
    id: 11,
    name: 'Unassembled Deep or Medium Frame',
    href: '#',
    imageSrc: 'images/products/frame1.jpg',
    imageAlt: "Unassembled Deep or Medium Frame",
    price: '$1.25'
  }, 
  {
    id: 12,
    name: 'Double Waxed Plastic deep or medium foundation',
    href: '#',
    imageSrc: 'images/products/frame2.jpg',
    imageAlt: "Frame boxes",
    price: '$1.25'
  },
  {
    id: 13,
    name: '5 Frame Plastic corrugated Nuc Box',
    href: '#',
    imageSrc: 'images/products/nuc1.jpg',
    imageAlt: "5 Frame Plastic corrugated Nuc Box",
    price: '$9'
  }, 
  
  {
    id: 14,
    name: '',
    href: '#',
    imageSrc: 'images/products/nuc2.jpg',
    imageAlt: "Frame boxes",
    price: ''
  }, 
  {
    id: 15,
    name: '',
    href: '#',
    imageSrc: 'images/products/nuc3.jpg',
    imageAlt: "Frame boxes",
    price: ''
  },  
  {
    id: 16,
    name: '',
    href: '#',
    imageSrc: 'images/products/nuc4.jpg',
    imageAlt: "Frame boxes",
    price: ''
  }, 
  {
    id: 17,
    name: '',
    href: '#',
    imageSrc: 'images/products/nuc5.jpg',
    imageAlt: "Frame boxes",
    price: ''
  }, 
  {
    id: 18,
    name: '1000 pcs JZBZ Queen bee Cell Cups',
    href: '#',
    imageSrc: 'images/products/cup.jpg',
    imageAlt: "Frame boxes",
    price: '$80'
  }, 
  {
    id: 19,
    name: '',
    href: '#',
    imageSrc: 'images/products/cup2.jpg',
    imageAlt: "JZBZ Queen bee Cell Cups",
    price: ''
  }, 
  {
    id: 20,
    name: '',
    href: '#',
    imageSrc: 'images/products/cup3.jpg',
    imageAlt: "Frame boxes",
    price: ''
  }, 
]

export default function App() {
  return (
    <div className="bg-white">
    {/* Logo in the top-left corner */}
    <div className="absolute top-0 left-0 p-4 z-50">
      <span className="sr-only">logo</span>
      <img alt="logo" src={logo} className="h-20 w-auto" />
    </div>
  
    {/* Main image with gradient */}
  <div className="relative">
    {/* Main image */}
    <img
      alt=""
      src="/images/main.jpg"
      className="w-full h-[20vh] sm:h-[30vh] object-cover"
    />
    
    {/* Gradient overlay */}
    <div className="absolute inset-0 bg-gradient-to-t from-white"/>

    {/* Phone and email fixed at the bottom-right corner of the main image */}
    <div className="absolute bottom-0 right-8 p-4 z-50">
      <div className="flex flex-col items-end text-right">
        <div className="flex items-center space-x-2">
          <span className="material-symbols-outlined">phone_in_talk</span>
          <a href="tel:2517515962" className="text-black-500 hover:underline">(251) 751-5962</a>
        </div>
        <div className="flex items-center space-x-2 mt-2">
          <span className="material-symbols-outlined">mail</span>
          <a href="mailto:inquiries@tampabeesupply.com" className="text-black-500 hover:underline">inquiries@tampabeesupply.com</a>
        </div>
      </div>
    </div>
  </div>
  
    {/* Content below the image */}
    <div className="relative mx-auto max-w-7xl px-4 pb-16 sm:px-6 sm:pb-24 lg:px-8">
      <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Tampa Bee Supplies
        </h2>
        <p className="mt-4 text-gray-500">
          As a wholesale supplier of premium beekeeping products, Tampa Bee Supplies provides high-quality, 
          durable bee boxes and essential beekeeping equipment in bulk. 
          Our products are designed for commercial and large-scale beekeeping operations, 
          ensuring a productive and sustainable environment for bees. 
          Partner with us to meet your wholesale needs and ensure a thriving bee colony.
        </p>
      </div>
  
      <div className="relative p-8">
        <div className="mx-auto max-w-7xl"></div>
        <ul className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-3 xl:gap-x-8">
          {products.map((item) => (
            <li key={item.imageSrc} className="relative">
              <div className="group aspect-h-9 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                <img alt="" src={item.imageSrc} className="pointer-events-none object-cover group-hover:opacity-75" />
              </div>
              <div className="mt-4 flex justify-between">
                <div>
                  <h3 className="text-sm text-gray-700">
                    <span aria-hidden="true" className="absolute inset-0"/>
                    {item.name}
                  </h3>
                </div>
                <p className="text-sm font-medium text-gray-900">{item.price}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  
    <footer className="bg-gray-100 p-4 mt-16 text-center">
      <p className="text-lg text-gray-500">
        Office Location: 510 N Turkey Creek Rd, Plant City, Fl 33563
      </p>
      <p className="text-sm text-gray-400">© 2024 Tampa Bee Supply. All rights reserved.</p>
    </footer>
  </div>
  );
}
